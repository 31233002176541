import styled from 'styled-components'

export const Base = styled.div`
  background-color: #F3F3F3;
`
export const Container = styled.div`
  max-width: 768px;
  min-width: 320px;
  min-height: 100vh;
  background-color: white;
  margin-left: auto;
  margin-right: auto;
`
