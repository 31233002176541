import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import Result from 'src/components/result/pages/Result'
import Ranking from 'src/components/ranking/pages/Ranking'
import Search from 'src/components/search/pages/Search'
import { Container, SubTitle, FormContainer, Input, Submit, Error, StyledTab, TabWrapper } from './style'
import { colors } from 'src/shared/utils/styles'
import { Tabs } from 'antd'
import 'antd/dist/antd.css'
import useFixedTabList from '../hooks/useFixedTabList'
import { tabKeys } from 'src/shared/constants/tab'
import { culculateFixedScrollTop } from 'src/shared/utils/culculateFixedScrollTop'
import { Formik, Form, useField } from 'formik'

const FIXED_SCROLL_TOP: number = culculateFixedScrollTop()

const Home: React.FC = () => {
  const [t] = useTranslation()
  const [error, setError] = useState("") // errorの状態
  const [resultNcode, setResultNcode] = useState("") // 判定結果のncode
  const [activeKey, setActiveKey] = useState(tabKeys.ranking) // タブの選択状態
  const [currentScrollY, setCurrentScrollY] = useState(0) // タブの選択状態
  const [searchCurrentScrollY, setSearchCurrentScrollY] = useState(0) // タブの選択状態

  // formのvalidation
  const validateForm = (urlForm: string) => {
    const [protocol, _, domain, ncode, param = ""] = urlForm.split("/")
    const result = (protocol === "https:") && (_ === "") && (domain === "ncode.syosetu.com") 
                  && (param === "" || param.match(/[0-9]/g)) && (ncode.length > 0 && ncode.match(/[^a-zA-Z_0-9]/g) == null)

    return result
  }

  // タブ選択時, アクティブなタブの切り替え
  const callback = (key: string) => {
    if (key !== tabKeys.ranking && activeKey === tabKeys.ranking) { 
      setCurrentScrollY(document.documentElement.scrollTop)
    }
    if (key !== tabKeys.search && activeKey === tabKeys.search) { 
      setSearchCurrentScrollY(document.documentElement.scrollTop)
    }
    if (key === tabKeys.result) { 
      // HACH: scroll値がマジックナンバー
      if(document.documentElement.scrollTop > FIXED_SCROLL_TOP) { window.scrollTo(0, FIXED_SCROLL_TOP) }
    }
    setActiveKey(key)
  }

  // タブリストの固定
  useFixedTabList()

  const placeholder = t('なろう小説のURLを入力')
  const { TabPane } = Tabs;

  useEffect(() => {
    if (activeKey === tabKeys.ranking) { window.scrollTo(0, currentScrollY) }
    if (activeKey === tabKeys.search) { window.scrollTo(0, searchCurrentScrollY) }
  }, [activeKey, currentScrollY, searchCurrentScrollY])

  const NovelUrlForm = ({ label, ...props }: any) => {
    const [field, meta] = useField(props)
    return (
      <>
        <label htmlFor={props.id || props.name}>{label}</label>
        <Input className="text-input" {...field} {...props} />
        {meta.touched && meta.error ? (
          <div className="error">{meta.error}</div>
        ) : null}
      </>
    )
  }
  return (
    <>
      <Container>
        <SubTitle>{t('エタらなろうは作者の完結率に着目して なろう小説のおすすめ度を判定します')}</SubTitle>
        <Formik
          initialValues={{url: ''}}
          onSubmit={ values => {
            const result = validateForm(values.url)
            if( result ) {
              const ncode = values.url.split("/")[3]
              setResultNcode(ncode)
              setActiveKey(tabKeys.result)
            } else {
              setError(t('なろう小説のURLを入力してください'))
            }
          }}
        >
          <Form>
            <FormContainer>
              <NovelUrlForm
                name="url"
                type="text"
                placeholder={placeholder}
              />
              <Submit color={colors.primary} type="submit">{t('判定')}</Submit>
            </FormContainer>
          </Form>
        </Formik>
        <Error>{error}</Error>

        <StyledTab>
          <Tabs defaultActiveKey={tabKeys.ranking} activeKey={activeKey} onChange={callback}>
            <TabPane tab="ランキング" key={tabKeys.ranking}>
              <Ranking setResultNcode={setResultNcode} setActiveKey={setActiveKey} setCurrentScrollY={setCurrentScrollY} />
            </TabPane>
            <TabPane tab="判定結果" key={tabKeys.result}>
              <TabWrapper>
                <Result resultNcode={resultNcode}/>
              </TabWrapper>
            </TabPane>
            <TabPane tab="小説検索" key={tabKeys.search}>
              <TabWrapper>
                <Search setResultNcode={setResultNcode} setActiveKey={setActiveKey} setSearchCurrentScrollY={setSearchCurrentScrollY} />
              </TabWrapper>
            </TabPane>
          </Tabs>
        </StyledTab>
      </Container>
    </>
  )
}

export default Home
