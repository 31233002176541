import { useEffect } from 'react'
import fetchJsonpCommon from 'src/shared/utils/fetchJsonpCommon'

interface searchParams {
  order: string
  start: number
  word?: string
  notword?: string
  biggenre?: string
  setSearchResult: any
}

const useSearchResult = (props: searchParams) => {
  const { order, start, word = '', notword = '', biggenre = '', setSearchResult } = props

  useEffect(() => {
    const buildSearchOptions = (word: string, notword: string) => {
      let searchOptions = ""
      if (word.length !== 0) { searchOptions += `&word=${word}`}
      if (notword.length !== 0) { searchOptions += `&notword=${notword}`}
      if (biggenre.length !==0 ) { searchOptions += `&biggenre=${biggenre}`}
      return searchOptions
    }

    const getApiInfo = async () => {
      const searchOptions = buildSearchOptions(word, notword)
      const urlForSearchResult = () => `https://api.syosetu.com/novelapi/api/?out=jsonp&lim=50&order=${order}&st=${start}${searchOptions}`
      const searchResult = await fetchJsonpCommon(urlForSearchResult()).then(response => response)

      // 取得したランキングからリスト表示用のデータを作成
      setSearchResult(searchResult)
    }

    getApiInfo()
  }, [order, start, word, notword, biggenre, setSearchResult])
}

export default useSearchResult
