import styled from 'styled-components'

export const Container = styled.div`
  width: 90%;
  margin: auto;
`

export const StyledButton = styled.div`
  margin-top: 20px;
  text-align: center;
`

export const Button = styled.button.attrs({
  type: 'submit'
})`
  background-color: ${props => props.color};
  color: white;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 3px 5px;
  text-align: center;
  font-size: 16px;
  width: 80%;
`

export const CheckboxWrapper = styled.span`
  margin-right: 10px;
`

