import { useEffect } from 'react'

const useFixedTabList = () => {
    useEffect(() => {
      const tablist = (document.getElementsByClassName('ant-tabs-bar') as HTMLCollectionOf<HTMLElement>)[0]
      const tabContent = (document.getElementsByClassName('ant-tabs-content') as HTMLCollectionOf<HTMLElement>)[0]
  
      tablist.style.position = "absolute"
      tablist.style.width = "100%"
        // HACK: 50pxは暫定の値。DOMを計算する形への変更が必要
      tabContent.style.padding = "50px 0"
  
      const tabFix = () => {
        // HACK: 115は暫定の値。DOMを計算する形への変更が必要
        if (document.documentElement.scrollTop > 115) {
          tablist.style.position = "fixed"
          tablist.style.top = "0"
          tablist.style.backgroundColor = "white"
          tablist.style.zIndex= "1"
        } else {
          tablist.style.position = "absolute"
        }
      }
      window.addEventListener('scroll', tabFix)
  
      return () => window.removeEventListener('scroll', tabFix)
    }, [])
}

export default useFixedTabList
