import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { colors } from 'src/shared/utils/styles'

const Link = styled.a.attrs({
  href: "/"
})`
  font-size: 18px;
  color: white;
  text-decoration: none;
`

const HeaderBorder = styled.div`
  border-bottom: 2px solid #33B7CD;
  padding: 3px 7px;
  background-color: ${props => props.color};
`

const Header: React.FC = () => {
  const [t] = useTranslation()
  return (
    <HeaderBorder color={colors.primary}>
      <Link>
        {t('エタらなろう')}
      </Link>
    </HeaderBorder>
  )
}

export default Header
