import React, { useState } from 'react'
import styled from 'styled-components/macro'
import useNovelRanking from '../hooks/useNovelRanking'
// import dummy from 'src/shared/utils/dummy'
import Title from '../Title'
import { StyledTitle } from './style'
import NovelList from 'src/shared/components/NovelList'
import { Pagination } from 'antd';
import { culculateFixedScrollTop } from 'src/shared/utils/culculateFixedScrollTop'

const FIXED_SCROLL_TOP: number = culculateFixedScrollTop()
const PAGE_SIZE = 50

// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import { faSearch } from '@fortawesome/free-solid-svg-icons'

export const TitleLink = styled.a`
  color: #F86432;
  text-decoration: none;
`

export const WriterLink = styled.a`
  text-decoration: none;
`

interface IRanking {
  setResultNcode: any,
  setActiveKey: any,
  setCurrentScrollY: any
}

const Ranking: React.FC<IRanking> = (props) => {
  const { setResultNcode, setActiveKey, setCurrentScrollY } = props
  const [page, setPage] = useState(0)

  // HACH: もうちょい何とかなるでしょ
  const today = new Date()
  today.setHours(today.getHours() - 7);
  const month = ("0" + (today.getMonth() + 1)).slice(-2)
  const day = ("0" + (today.getDate())).slice(-2)
  const date = `${today.getFullYear() + month + day}-d`
  const titleDate = `${today.getFullYear()}年${month}月${day}日`

  const start = page * PAGE_SIZE
  const rank = useNovelRanking(date)
  const rankLength = rank.length

  const slicedRank = rank.slice(start, start + PAGE_SIZE)

  const handleChange = (page: any, pageSize: any) => {
    setPage(page - 1)
    window.scrollTo(0, FIXED_SCROLL_TOP)
  }

  return (
    <>
      <StyledTitle>
        <Title date={titleDate}/>
      </StyledTitle>
      <NovelList novelList={slicedRank} setResultNcode={setResultNcode} setActiveKey={setActiveKey} setCurrentScrollY={setCurrentScrollY} />
      <Pagination defaultCurrent={1} total={rankLength} pageSize={PAGE_SIZE} onChange={handleChange} pageSizeOptions={['50']} showLessItems={true} showSizeChanger={false}/>
    </>
  )
}

export default Ranking
