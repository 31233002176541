import React from 'react'
import { Container, Title, TitleLink, Writer, WriterLink, Story } from './style'

interface INovelInfo {
  title: string,
  ncode: string,
  writer: string,
  userid: string
  story: string
}

const NovelInfo: React.SFC<INovelInfo> = (props: any) => {
  const { title, writer, story, ncode, userid } = props
  const novelPath = `https://ncode.syosetu.com/${ncode}/`
  const userPath = `https://mypage.syosetu.com/${userid}/`

  return (
    <Container>
      <Title>
        <TitleLink href={novelPath} target="_blank" rel="noopener noreferrer">
          {title}
        </TitleLink>
      </Title>
      <Writer>
        <WriterLink href={userPath} target="_blank" rel="noopener noreferrer">
          {writer}
        </WriterLink>
      </Writer>
      <Story>{story}</Story>
    </Container>
  )
}

export default NovelInfo
