import React, { useEffect, useState } from 'react'
import { Box, NovelTitle, Option, Footer, Label, LabelSpan, Result, ResultNote, ResultLink, TitleLink, WriterLink, Genre } from './style'
import { useTranslation } from 'react-i18next'
import { colors } from 'src/shared/utils/styles'
import { tabKeys } from 'src/shared/constants/tab'
import { culculateFixedScrollTop } from 'src/shared/utils/culculateFixedScrollTop'
import getNovelInfo from 'src/shared/utils/getNovelInfoForList'

const genres: {[index: string]: string} = {
  101: '異世界〔恋愛〕',
  102: '現実世界〔恋愛〕',
  201: 'ハイファンタジー〔ファンタジー〕',
  202: 'ローファンタジー〔ファンタジー〕',
  301: '純文学〔文芸〕',
  302: 'ヒューマンドラマ〔文芸〕',
  303: '歴史〔文芸〕',
  304: '推理〔文芸〕',
  305: 'ホラー〔文芸〕',
  306: 'アクション〔文芸〕',
  307: 'コメディー〔文芸〕',
  401: 'VRゲーム〔SF〕',
  402: '宇宙〔SF〕',
  403: '空想科学〔SF〕',
  404: 'パニック〔SF〕',
  9901: '童話〔その他〕',
  9902: '詩〔その他〕',
  9903: 'エッセイ〔その他〕',
  9904: 'リプレイ〔その他〕',
  9999: 'その他〔その他〕',
  9801: 'ノンジャンル〔ノンジャンル〕',
}

const FIXED_SCROLL_TOP: number = culculateFixedScrollTop()

interface INovelList {
  novelList: any
  setResultNcode: any
  setActiveKey: any
  setCurrentScrollY: any
}

const NovelList: React.FC<INovelList> = props => {
  const { novelList, setResultNcode, setActiveKey, setCurrentScrollY } = props
  const [t] = useTranslation()
  const [result, setResult] = useState<any>([])

  useEffect(() => {
    const getApiInfo = async () => {
      if (novelList.length !== 0) {
        // 取得したランキングからリスト表示用のデータを作成
        const result = await getNovelInfo(novelList)
        setResult(result)
      } else {
        setResult([])
      }
    }

    getApiInfo()
  }, [novelList])

  const handleClick = (ncode: string) => {
    setResultNcode(ncode)
    setActiveKey(tabKeys.result)
    setCurrentScrollY(document.documentElement.scrollTop)
    window.scrollTo(0, FIXED_SCROLL_TOP)
  }

  return (
    result.length === 0 ? <div>loading...</div> :
    result.map((novel: any) => {
      if (novel === false) return <div></div>
      const { 
        title, writer, completed, rank, series, completedRate, lastEpisodesAverage, ncode,
        userid, genre, general_firstup, general_lastup, general_all_no
      } = novel
      const novelPath = `https://ncode.syosetu.com/${ncode}/`
      const userPath = `https://mypage.syosetu.com/${userid}/`

      // 平均更新間隔の算出
      // .replace(/-/g , "/")はSafariの不具合対策
      // https://cpoint-lab.co.jp/article/201711/233/
      const averageInterval = (Date.parse(general_lastup.replace(/-/g , "/")) - Date.parse(general_firstup.replace(/-/g , "/"))) / 1000 / 60 / 60 / 24 / general_all_no
      const roundedAverageInterval = (Math.round(averageInterval * 10) / 10).toString()

      return(
        <Box key={ncode}>
          <div css="font-size: 15px;">
            {rank}
            <TitleLink href={novelPath} target="_blank" rel="noopener noreferrer">
              <NovelTitle>{title}</NovelTitle>
            </TitleLink>
          </div>
          <Option>
            <WriterLink href={userPath} target="_blank" rel="noopener noreferrer">
              作者: {writer}
            </WriterLink>
            <Genre>
              ジャンル: {genres[genre.toString()]}
            </Genre>
          </Option>
          <Footer>
            <Label><LabelSpan color={colors.secondary}>{t('過去作完結率')}</LabelSpan></Label>
            <Label><LabelSpan color={colors.secondary}>{t('平均更新間隔')}</LabelSpan></Label>
            <Label><LabelSpan color={colors.secondary}>{t('最終話平均(最大3作)')}</LabelSpan></Label>

            <Result>
              {completedRate}%
            </Result>
            <Result>
              {`${roundedAverageInterval}日`}
            </Result>
            <Result>
              {`${lastEpisodesAverage}話`}
            </Result>

            <ResultNote>{`(${series}作中${completed}作)` }</ResultNote>
            <div></div>
            <div css="text-align: right;">
              <ResultLink 
                onClick={() => { handleClick(ncode)}}
                color={colors.primary}
              >
                {t('判定')}
              </ResultLink>
            </div>
          </Footer>
        </Box>
      )
    })
  )
}

export default NovelList
