import styled from 'styled-components'

export const Container = styled.div`
  margin: 10px 10px;
`
export const Title = styled.h1`
  font-size: 18px;
  line-height: 130%;
  text-align: center;
  margin-bottom: 8px;
  text-decoration: none;
`
export const TitleLink = styled.a`
  color: #F86432;
  text-decoration: none;
`
export const Writer = styled.p`
  font-size: 13px;
  text-align: right;
  margin-bottom: 13px;
`
export const WriterLink = styled.a`
  text-decoration: none;
`
export const Story = styled.p`
  font-size: 13px;
`
