import React from 'react'
import { Formik, Form, useField } from 'formik'
import { Input } from 'antd'
import { colors } from 'src/shared/utils/styles'
import styled from 'styled-components/macro' // eslint-disable-line
import { Container, StyledButton, Button, CheckboxWrapper } from './style'

const TextInput = ({ label, ...props }: any) => {
  const [field] = useField(props)
  return (
    <div css="margin-top: 5px">
      <label htmlFor={props.id || props.name}>{label}</label>
      <Input className="text-input" css="font-size: 16px;" {...field} {...props} />
    </div>
  );
};

const Checkbox = ({ label, ...props }: any) => {
  const [field] = useField(props)

  return (
    <CheckboxWrapper>
      <input type="checkbox" {...field} {...props} />
      <label htmlFor={props.id || props.name} css="margin-left: 2px;">{label}</label>
    </CheckboxWrapper>
  )
}

interface ISearchForm {
  setWord: any
  setNotword: any
  setBiggenre: any
  setSearchResult: any
  word: any
  notword: any
  biggenre: any
}

const SearchForm: React.FC<ISearchForm> = (props) => {
  const { setWord, setNotword, setBiggenre, setSearchResult, word, notword, biggenre } = props
  return (
    <Container>
      <Formik
      initialValues={{ word: '', notword: '', biggenre: [] }}
      onSubmit={ (values, formikBag) => {
        if (!(values.biggenre.join('-') === biggenre && values.word === word && values.notword === notword)) {
          setSearchResult([])
          setBiggenre(values.biggenre.join('-'))
          setWord(values.word)
          setNotword(values.notword)  
        }
      }}
      >
        <Form>
          <TextInput
            label="検索"
            name="word"
            type="text"
            placeholder="検索ワードを入力"
          />
          <TextInput
            label="除外"
            name="notword"
            type="text"
            placeholder="除外ワードを入力"
          />
          <div css="margin-top: 5px;">ジャンル</div>
          <Checkbox
            label="恋愛"
            name="biggenre"
            value="1"
          />
          <Checkbox
            label="ファンタジー"
            name="biggenre"
            value="2"
          />
          <Checkbox
            label="文芸"
            name="biggenre"
            value="3"
          />
          <Checkbox
            label="SF"
            name="biggenre"
            value="4"
          />
          <Checkbox
            label="その他"
            name="biggenre"
            value="99"
          />
          <Checkbox
            label="ノンジャンル"
            name="biggenre"
            value="98"
          />
          <StyledButton>
            <Button type="submit" color={colors.primary}>検索</Button>
          </StyledButton>
        </Form>
      </Formik>
    </Container>
  )
}

export default SearchForm
