import React from 'react';
import { Switch, Route } from "react-router-dom";
import Home from './home/pages/Home'
import Header from './header'

import i18n from 'i18next'
import { initReactI18next } from 'react-i18next';
import jaJson from '../locales/ja.json'
import { Base, Container } from './style'

i18n.use(initReactI18next).init({
  resources: { ja: { translation: jaJson} },
  lng: 'ja',
  fallbackLng: false, // フォールバックしない＝keyをそのまま表示
  returnEmptyString: false, // 空文字での定義を許可
})

const App = () => {
  return (
    <div className="App">
      <Base>
        <Container>
          <Header />
          <Switch>
            <Route path="/">
              <Home />
            </Route>
          </Switch>
        </Container>
      </Base>
    </div>
  );
}

export default App;
