import styled from 'styled-components'

export const StyledTitle = styled.div`
  margin: 20px 0 7px 0;
`

export const Box = styled.div`
  position: relative;
  border-top: 1px solid #e5e5e5;
  padding: 3px 5px 10px 5px;
`

export const NovelTitle = styled.span`
  color: #F86432;
  margin-left: 3px;
  font-size: 15px;
`

export const Option = styled.div`
  font-size: 13px;
  margin: 5px 0 5px 0;
`

export const Footer = styled.div`
  display: grid;
  grid-template-rows: 25px 20px 25px;
  grid-template-columns: 1fr 1fr 1fr;
  font-size: 13px;
  white-space: nowrap;
`

export const Label = styled.div`
  text-align: center;
  line-height: 30px;
`

export const LabelSpan = styled.span`
  background-color: ${props => props.color};
  padding: 1px;
  border-radius: 3px;
`

export const Result = styled.div`
  text-align: center;
  font-size: 18px;
`

export const ResultNote = styled.div`
  text-align: center;
  font-size: 13px;
`

export const ResultLink = styled.div`
  position: absolute;
  bottom: 3px;
  right: 3px;
  /* height: 23px; */
  border: solid 1px;
  border-radius: 5px;
  padding: 1px 5px;
  /* font-size: 10px; */
  color: ${ props => props.color };
  text-decoration: none;
  cursor : pointer;
`

export const TitleLink = styled.a`
  color: #F86432;
  text-decoration: none;
`

export const WriterLink = styled.a`
  text-decoration: none;
`

export const Genre = styled.div`
  font-size: 13px;
`
