import { useEffect } from 'react'
import ReactGA from 'react-ga';

ReactGA.initialize('UA-159393636-1');

const useGaTrackPage = (page: string) => {
  useEffect(() => {
    ReactGA.pageview(page);
  }, [page])
}

export default useGaTrackPage
