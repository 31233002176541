import styled from 'styled-components';

export const Border = styled.div`
  border-bottom: 1px solid #e5e5e5;
  padding-bottom: 20px;
`
export const Container = styled.div`
  margin-bottom: 50px;
`
export const ResultContainer = styled.div`
  margin: 10px 10px;
`
export const ResultElementsContainer = styled.div`
  padding-top: 20px;
  display: grid;
  grid-template-rows: 40px 100%;
  grid-template-columns: 33% 33% 33%;
  text-align: center;
`
export const ResultCommentContainer = styled.div`
  padding-top: 20px;
`
export const Label = styled.div`
  font-size: 13px;
  height: 30px;
`
export const LabelSpan = styled.span`
  background-color: #F9F0BB;
  padding: 3px;
  border-radius: 3px;
  margin-right: 3px;
`
export const Remark = styled.div`
  font-size: 13px;
`
export const Content = styled.div`
  font-size: 30px;
`
export const Comment = styled.div`
  margin-top: 10px;
  font-size: 20px;
`
export const ResultComent = styled.div`
  text-align: center;
`
export const Rank = styled.div`
  margin-top: 20px;
  font-size: 50px;
`
