import fetchJsonpCommon from 'src/shared/utils/fetchJsonpCommon'

interface IRank {
  ncode: string
  pt: number
  rank: number
}

interface INovelInfo {
  title: string
  ncode: string
  userid: number
  writer: string
  story: string
  general_all_no: number
  general_firstup: string
  general_lastup: string
}

const getAllNovelInfo = async (apiUrl: string) => {
  let completedInfo = await fetchJsonpCommon(apiUrl).then(
    (response) => response
  )

  if (completedInfo[0].allcount > 500) {
    const queue = Math.ceil(completedInfo[0].allcount / 500) - 1

    // eslint-disable-next-line
    for (const _ of Array(queue).fill('')) {
      const lastGeneralLastUp = Math.floor(
        Date.parse(completedInfo.slice(-1)[0].general_lastup) / 1000
      )
      const apiUrlWithLastup = apiUrl + `&lastup=-${lastGeneralLastUp - 1}`
      const tmpCompletedInfo = await fetchJsonpCommon(apiUrlWithLastup).then(
        (response) => response
      )
      completedInfo = completedInfo.concat(tmpCompletedInfo)
    }
  }

  return completedInfo
}

const buildResult = (apiResult: any) => {
  const [rank, novelInfo, completedInfo, seriesInfo]: [
    IRank[],
    any[],
    INovelInfo[],
    INovelInfo[]
  ] = apiResult
  // 作者毎の連載作品数を要素として持つハッシュを作成する
  // フォーマット: { userid1: 連載作品数, userid2: 連載作品数, ... }
  let seriesCount: { [userid: string]: number } = {}
  seriesInfo.forEach((info) =>
    seriesCount[info.userid]
      ? (seriesCount[info.userid] += 1)
      : (seriesCount[info.userid] = 1)
  )

  // 完結作品を最終更新日でソート
  const sortedCompletedInfo = completedInfo
    .slice(1)
    .sort((a: INovelInfo, b: INovelInfo) => {
      if (a.general_lastup > b.general_lastup) {
        return -1
      }
      if (a.general_lastup < b.general_lastup) {
        return 1
      }
      return 0
    })

  // 完結作品をuseridをkeyとするハッシュにまとめる
  // フォーマット: { userid1: [完結済み作品1, 2, 3, ...], userid2: [完結済み連載作品1, 2, 3, ... }
  let completedInfoByUserid: { [userid: string]: INovelInfo[] } = {}
  sortedCompletedInfo.forEach((info) =>
    completedInfoByUserid[info.userid]
      ? completedInfoByUserid[info.userid].push(info)
      : (completedInfoByUserid[info.userid] = [info])
  )

  // 小説毎のランクを要素としてもつハッシュを作成する
  // フォーマット: { ncode1: rank1, ncode2: rank2, ... }
  const rankHash: { [index: string]: number } = {}
  rank.forEach((r) => Object.assign(rankHash, { [r.ncode]: r.rank }))

  // novelInfo(日刊ランキング作品の小説情報)に上で作成した、小説毎のランク/作者毎の連載作品数/作者毎の完結済み連載作品数 などなどを合成
  const result = novelInfo.map((info) => {
    // HACK: slice(1)は不要なallcountの削除が目的。正しい型が定義できればおそらく不要になる
    const completedRate = completedInfoByUserid[info.userid]
      ? Math.floor(
          (completedInfoByUserid[info.userid].length /
            seriesCount[info.userid]) *
            100
        )
      : 0
    const completed = completedInfoByUserid[info.userid]
      ? completedInfoByUserid[info.userid].length
      : 0

    const buildLastEpisodesAverage = (
      completedInfoByUserid: { [userid: string]: INovelInfo[] },
      userid: string
    ) => {
      const TARGET_RANGE = 3 // 過去X回分
      if (completedInfoByUserid[userid]) {
        const lastEpisodes = completedInfoByUserid[userid]
          .slice(0, TARGET_RANGE)
          .map((serial: any) => serial.general_all_no)
        return Math.floor(
          lastEpisodes.reduce((a: number, b: number) => a + b) /
            lastEpisodes.length
        )
      } else {
        return 0
      }
    }
    // lastEpisodeの平均値(小数点以下切り捨て)を返す
    const lastEpisodesAverage = buildLastEpisodesAverage(
      completedInfoByUserid,
      info.userid
    )

    return Object.assign(info, {
      rank: rankHash[info.ncode],
      series: seriesCount[info.userid] || 0,
      completedRate: completedRate,
      lastEpisodesAverage: lastEpisodesAverage,
      completed: completed,
    })
  })

  return result
}

const getNovelInfoForList = async (novels: []) => {
  // novelsの小説情報を取得
  const ncodes = novels.map((r: any) => r.ncode).join('-')
  const urlForNovelInfo = `https://api.syosetu.com/novelapi/api/?of=t-n-w-u-ga-g-gf-gl&ncode=${ncodes}&out=jsonp&lim=500`
  const novelInfo = await fetchJsonpCommon(urlForNovelInfo).then(
    (response) => response
  )

  const sortedNovelInfo = novels.map((novel: any) =>
    novelInfo.find((info: any) => info.ncode === novel.ncode)
  ).filter(e => e)

  // 作者idを取得
  const userids = sortedNovelInfo.map((n: any) => n.userid).join('-')

  // 作者過去連載作を取得
  const urlForSeriesNovel = `https://api.syosetu.com/novelapi/api/?of=u-ga-t-gl&order=new&userid=${userids}&type=re&out=jsonp&lim=500`
  const seriesInfo = await getAllNovelInfo(urlForSeriesNovel)

  // 作者過去連載完結作を取得
  const urlForFinishedNovel = `https://api.syosetu.com/novelapi/api/?of=u-ga-t-gl&order=new&userid=${userids}&type=er&out=jsonp&lim=500`
  const completedInfo = await getAllNovelInfo(urlForFinishedNovel)

  return buildResult([novels, sortedNovelInfo, completedInfo, seriesInfo])
}

export default getNovelInfoForList
