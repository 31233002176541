import styled from 'styled-components'

export const TitleWrapper = styled.div`
  display: flex;
  white-space: nowrap;
  margin-left: 3px;
`

export const Heading = styled.div`
  font-size: 18px;
`

export const Date = styled.div`
  margin-top: auto;
  margin-left: 8px;
  font-size: 13px;
`
