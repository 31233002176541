import React from 'react'
import { useTranslation } from 'react-i18next'
import { QuestionCircleOutlined } from '@ant-design/icons'
import { Popover } from 'antd'
import { ResultContainer, ResultElementsContainer, Label, LabelSpan, Content, Remark, ResultCommentContainer, ResultComent, Comment, Container, Border, Rank } from './style'
import buildComment from './buildComment'

interface IResultContent {
  latest: number,
  completionRate: number,
  lastEpisodesAverage: number,
  completedNum: number,
  allNum: number,
}

const ResultElements: React.FC = (props: any) => {
  const [t] = useTranslation()
  const { latest, completionRate, lastEpisodesAverage, completedNum, allNum } = props

  const content = <div>作者連載作完結率は「小説家になろう」マイページ 作品一覧の「連載」/「完結済み」作品数から算出しています</div>
  const start = (
    <Popover content={content} trigger="click">
      <QuestionCircleOutlined />
    </Popover>
  )

  return (
    <ResultContainer>
      <ResultElementsContainer>
        <div>
          <Label><LabelSpan>{t('作者連載作完結率')}</LabelSpan>{start}</Label>
        </div>
        <div>
          <Label><LabelSpan>{t('最新話')}</LabelSpan></Label>
        </div>
        <div>
          <Label><LabelSpan>{t('最終話平均(最大過去3作)')}</LabelSpan></Label>
        </div>
        <div>
          <Content>{completionRate}%</Content>
          <Remark>({allNum}作中 {completedNum}作完結)</Remark>
        </div>
        <div>
          <Content>{latest}話</Content>
        </div>
        <div>
          <Content>{lastEpisodesAverage ? `${lastEpisodesAverage}話` : '-'}</Content>
        </div>
      </ResultElementsContainer>
    </ResultContainer>
  )
}

const ResultComment: React.FC<{ comment: string }> = (props: any) => {
  const [t] = useTranslation()
  const { latest, completionRate, lastEpisodesAverage, allNum } = props

  let { etaruComment, termCommentEnable, termComment, rank } = buildComment(allNum, t, completionRate, latest, lastEpisodesAverage)

  return (
    <ResultContainer>
      <ResultCommentContainer>
        <ResultComent>
          {t('おすすめ度')}
          <Rank>{rank}</Rank>
          <Comment>{etaruComment}</Comment>
          <Comment>{termCommentEnable && termComment}</Comment>
        </ResultComent>
      </ResultCommentContainer>
    </ResultContainer>
  )
}

const ResultContent: React.FC<IResultContent> = (props: any) => {
  return (
    <Container>
      <Border/>
      <ResultElements { ...props } />
      <Border/>
      <ResultComment { ...props } />
    </Container>
  )
}

export default ResultContent
