const buildComment = (allNum: number, t: any, completionRate: number, latest: number, lastEpisodesAverage: number) => {
  let etaruComment = ''
  let termComment = ''
  let termCommentEnable = false
  let point = 0
  const pointRank: {[key: string]: number} = { "D": 0, "C": 1, "C+": 2, "B": 3, "B+": 4, "A": 5, "A+": 6, "S": 7 }

  if (allNum < 2) {
    etaruComment = t('初連載！応援したい！！')
    point += 1
  }
  else {
    if (completionRate < 20) {
      etaruComment = t('もしかしたらエタるかもしれない。注意しよう...')
    }
    else if (completionRate < 50) {
      etaruComment = t('完結してくれると嬉しいけれど...')
      point += 1
    }
    else if (completionRate < 70) {
      etaruComment = t('完結してくれそう！')
      termCommentEnable = true
      point += 2
    }
    else {
      etaruComment = t('完結まで書いてくれるとてもありがたい作者様！')
      termCommentEnable = true
      point += 3
    }

    if (latest / lastEpisodesAverage < 0.2) {
      termComment = t('始まったばかり。追いかけるなら今！')
      termCommentEnable && (point += 4)
    }
    else if (latest / lastEpisodesAverage < 0.4) {
      termComment = t('まだまだ続きそう。長く楽しめる！')
      termCommentEnable && (point += 3)
    }
    else if (latest / lastEpisodesAverage < 0.7) {
      termComment = t('完結まで半分は超えているかも？')
      termCommentEnable && (point += 2)
    }
    else if (latest / lastEpisodesAverage < 1) {
      termComment = t('そろそろ終わりが見えてくる頃？')
      termCommentEnable && (point += 1)
    }
    else {
      termComment = t('いつまで続くのか、もしくは終わるのか、もう誰も予想できない。。。！')
      termCommentEnable && (point += 1)
    }
  }

  const rank = Object.keys(pointRank).find( key => pointRank[key] === point)

  return { etaruComment, termCommentEnable, termComment, rank }
}

export default buildComment

