import { useState, useEffect } from 'react'
import fetchJsonpCommon from 'src/shared/utils/fetchJsonpCommon'

const useNovelRanking = (date: string) => {
  const [result, setResult] = useState<any>([])

  useEffect(() => {
    const getApiInfo = async () => {
      // 日刊ランキングを取得(ncode, point, rankのみ)
      const urlForRanking = (date: string) => `https://api.syosetu.com/rank/rankget/?rtype=${date}&out=jsonp`
      const rankTmp = await fetchJsonpCommon(urlForRanking(date)).then(response => response)
      const rank = rankTmp.sort((a: any, b: any) => {
        return a.rank - b.rank
      })

      // 取得したランキングからリスト表示用のデータを作成
      setResult(rank)
    }

    getApiInfo()
  }, [date])

  return result
}

export default useNovelRanking
