import React from 'react';
import { TitleWrapper, Date, Heading } from './style'

interface ITitle {
  date: string,
}

const Title: React.FC<ITitle> = ({ date }) => {

  return(
    <TitleWrapper>
      <Heading>日刊ランキングBest300</Heading>
      <Date>{date}</Date>
    </TitleWrapper>
  )
}

export default Title
