import React, { useState } from 'react'
import NovelList from 'src/shared/components/NovelList'
import useSearchResult from '../hooks/useSearchResult'
import { Select } from 'antd'
import { Pagination } from 'antd';
import SearchForm from '../SearchForm'
import { culculateFixedScrollTop } from 'src/shared/utils/culculateFixedScrollTop'
import styled from 'styled-components'

const FIXED_SCROLL_TOP: number = culculateFixedScrollTop()
const PAGE_SIZE = 50

const NovelListContainer = styled.div`
  margin-top: 30px;
`

interface IRanking {
  setResultNcode: any,
  setActiveKey: any,
  setSearchCurrentScrollY: any
}

const Search: React.FC<IRanking> = (props) => {
  const { setResultNcode, setActiveKey, setSearchCurrentScrollY } = props
  const [order, setOrder] = useState('new')
  const [page, setPage] = useState(0)
  const [word, setWord] = useState('')
  const [notword, setNotword] = useState('')
  const [biggenre, setBiggenre] = useState('')
  const [searchResult, setSearchResult] = useState([])
  const { Option } = Select

  const orderOptions: {[index: string]: string} = {
    new: '新着更新順',
    dailypoint: '日間ポイントの高い順',
    weeklypoint: '週間ポイントの高い順'
  }

  const handleOrderChange = (event: any) => {
    setPage(0)
    setOrder(event)
    setSearchResult([])
  }

  const handleChange = (page: any, pageSize: any) => {
    setPage(page - 1)
    window.scrollTo(0, FIXED_SCROLL_TOP)
    setSearchResult([])
  }

  useSearchResult({ order: order, start: page * PAGE_SIZE, word: word, notword: notword, biggenre: biggenre, setSearchResult: setSearchResult })
  const [searchResultTop = { allcount: 0 }, ...searchResultList ] = searchResult

  const searchLength = searchResultTop.allcount > 2000 ? 2000 : searchResultTop.allcount

  return (
    <>
      <SearchForm setWord={setWord} setNotword={setNotword} setBiggenre={setBiggenre} setSearchResult={setSearchResult} word={word} notword={notword} biggenre={biggenre}/>
      <NovelListContainer>
        <Select
          defaultValue={orderOptions['new']}
          style={{ width: 200, marginBottom: 10, fontSize: 16 }}
          onChange={handleOrderChange}
        >
            {Object.keys(orderOptions).map((key: string) => (
              <Option key={key} value={key}>{orderOptions[key]}</Option>
            ))}
        </Select>
        <NovelList novelList={searchResultList} setResultNcode={setResultNcode} setActiveKey={setActiveKey} setCurrentScrollY={setSearchCurrentScrollY} />
        <Pagination defaultCurrent={1} total={searchLength} pageSize={PAGE_SIZE} onChange={handleChange} pageSizeOptions={['50']} showLessItems={true} showSizeChanger={false}/>
      </NovelListContainer>
    </>
  )
}

export default Search
