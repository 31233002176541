import React from 'react'
import NovelInfo from '../NovelInfo'
import ResultContent from '../ResultContent'
import useNovelAuthorInfo from '../hooks/useNovelAuthorInfo'
import useGaTrackPage from 'src/shared/hooks/useGaTrackPage'
import { useLocation } from 'react-router-dom'
import styled from 'styled-components/macro' // eslint-disable-line

interface IResult {
  resultNcode: string
}

const Result: React.FC<IResult> = (props) => {
  const { resultNcode } = props
  const { title, ncode, writer, userid, story, latest, completionRate, lastEpisodesAverage, completedNum, allNum } = useNovelAuthorInfo(resultNcode || '')
  const NovelInfoProps = { title, ncode, writer, story, userid }
  const ResultContentProps = { latest, completionRate, lastEpisodesAverage, completedNum, allNum }

  const location = useLocation()
  const ncodeValid = (resultNcode !== undefined) && (resultNcode !== "undefined") && (resultNcode.length > 0) && (resultNcode.match(/[^a-zA-Z_0-9]/g) == null)

  useGaTrackPage(location.pathname)

  return ncodeValid ?
    (
      <>
        <NovelInfo { ...NovelInfoProps } />
        <ResultContent { ...ResultContentProps}/>
      </>
    )
    :
      <>
        <div css="height: 800px;"> なろう小説のURLを入力してください </div>
      </>
}

export default Result
