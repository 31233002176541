import styled from 'styled-components'

export const Container = styled.div`
  margin: 10px 0;
`

export const SubTitle = styled.div`
  font-size: 15px;
  line-height: 130%;
  text-align: center;
  margin-bottom: 15px;
  width: 92%;
  margin-right: auto;
  margin-left: auto;
`

export const FormContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 15px;
  height: 30px;
`

export const Input = styled.input.attrs({
  type: 'input'
})`
  width: 85%;
  border: 1px solid #ccc;
  border-radius: 5px 0 0 5px;
  box-shadow: inset 0 2px 0 0 #eee;
  padding: 1px;
  text-align: center;
  font-size: 16px;
`

export const Submit = styled.button.attrs({
  type: 'submit'
})`
  background-color: ${props => props.color};
  border-radius: 0 5px 5px 0;
  color: white;
  padding: 2px 10px;
  font-size: 13px;
`

export const Error = styled.div`
  text-align: center;
  color: red;
  margin-top: 10px;
`

export const StyledTab = styled.div`
  margin-left: 3px;
`

// iOS13 & Ant Design使用時の不具合のための対応
// https://github.com/ant-design/ant-design-mobile/issues/3455
export const TabWrapper = styled.div`
  margin-top: 20px;
  transform: translate(0px, 0px);
  min-height: calc(100vh - 50px);
`
