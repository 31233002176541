import { useState, useEffect } from 'react'
import fetchJsonp from 'fetch-jsonp'

const useNovelAuthorInfo = (ncode: string) => {
  interface INovelInfo {
    title: string,
    writer: string,
    story: string,
    latest: number,
  }
  interface IAuthorInfo {
    userid: string,
    completedNum: number,
    allNum: number,
    lastEpisodesAverage: number,
  }
  const initialNovelInfo = { title: "", writer: "", story: "", latest: 0 }
  const initialAuthorInfo = { userid: "", completedNum: 0, allNum: 0, lastEpisodesAverage: 0 }
  const [novelInfo, setNovelInfo] = useState<INovelInfo>(initialNovelInfo)
  const [authorInfo, setAuthorInfo] = useState<IAuthorInfo>(initialAuthorInfo)

  useEffect(() => {
    const getApiInfo = async () => {
      const fetchJsonpCommon = (url: string) => (
        fetchJsonp(url).then(response =>  response.json())
      )
      const urlForNovel = (ncode: string) => `https://api.syosetu.com/novelapi/api/?of=t-w-u-i-ga-ti-s&ncode=${ncode}&out=jsonp`

      // 小説情報の取得
      const { title, writer, story, general_all_no, userid } = await fetchJsonpCommon(urlForNovel(ncode)).then(response => response[1])

      // 作者全作品数の取得
      const urlForAllSerial = `https://api.syosetu.com/novelapi/api/?of=t&userid=${userid}&type=re&out=jsonp`
      const promiseAllSerial = fetchJsonpCommon(urlForAllSerial).then(response => response[0].allcount)
      // 完結作品数 & 完結作品最終話(過去3回) の取得
      const urlForCompletedSerial = `https://api.syosetu.com/novelapi/api/?of=ga&order=new&userid=${userid}&type=er&out=jsonp`
      const promiseCompleteSerial = fetchJsonpCommon(urlForCompletedSerial).then(response => (
        (response.length === 1) ? [0, ""] : [response[0].allcount, response.slice(1, 4)]
      ))

      const [allNum, [completedNum, completedSerials]] = await Promise.all([promiseAllSerial, promiseCompleteSerial])

      // HACK: 微妙に形が気に入らない。if/elseそれぞれの中でreturn している点
      const buildLastEpisodesAverage = (completedSerials: []) => {
        if (completedSerials.length !== 0) {
          const lastEpisodes = completedSerials.map((serial: any) => serial.general_all_no)
          // lastEpisodeの平均値(小数点以下切り捨て)を返す
          return Math.floor(lastEpisodes.reduce((a: number, b: number) => a + b ) / completedSerials.length)
        } else {
          return 0
        }
      }

      const lastEpisodesAverage = buildLastEpisodesAverage(completedSerials)

      setAuthorInfo({ userid, allNum, completedNum, lastEpisodesAverage })
      setNovelInfo({ title, writer, story, latest: general_all_no })
    }

    getApiInfo()
  }, [ncode])

  return {
    title: novelInfo.title,
    ncode: ncode,
    writer: novelInfo.writer,
    userid: authorInfo.userid,
    story: novelInfo.story,
    latest: novelInfo.latest,
    completedNum: authorInfo.completedNum,
    allNum: authorInfo.allNum,
    completionRate: Math.round((authorInfo.completedNum / authorInfo.allNum) * 100),
    lastEpisodesAverage: authorInfo.lastEpisodesAverage,
  }
}

export default useNovelAuthorInfo
